/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import '@fontsource/manrope'
import '@fontsource/manrope/300.css'
import '@fontsource/manrope/600.css'
import '@fontsource/manrope/800.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './src/theme/_global.scss'

export const onPreRouteUpdate = () => {
  const url = typeof window !== 'undefined' ? window.location.pathname : ''
  if (url !== '/') {
    document.body.scrollTop = 0
  }
}

export { wrapRootElement } from './gatsby-ssr'

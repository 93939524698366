import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { I18nextProvider } from 'react-i18next'
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import i18next from '../i18n/config'
import { MenuProvaider } from '../hooks/use-menu'
import { UIProvider } from '../hooks/use-ui'

const wrapRootElement = (props) => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
    <I18nextProvider i18n={i18next}>
      <ThemeProvider breakpoints={['xxl', 'xl', 'lg', 'md', 'sm', 'xs']}>
        <MenuProvaider>
          <UIProvider>{props.element}</UIProvider>
        </MenuProvaider>
      </ThemeProvider>
    </I18nextProvider>
  </GoogleReCaptchaProvider>
)

export default wrapRootElement

import React from 'react'
import IconFacebook from '../../assets/icons/social-media/pacificcross_icons_socialmedia_facebook.svg'
import IconLinkedin from '../../assets/icons/social-media/pacificcross_icons_socialmedia_linkedin.svg'
import IconYoutube from '../../assets/icons/social-media/pacificcross_icons_socialmedia_youtube.svg'
import IconInstagram from '../../assets/icons/social-media/pacificcross_icons_socialmedia_instagram.svg'
import examination from '../../assets/images/products/examination.jpg'
import hospitalization from '../../assets/images/products/hospitalization.jpg'
import nonSurgery from '../../assets/images/products/nonsurgicaltreatment.jpg'
import outPatient from '../../assets/images/products/outpatient.jpg'
import postHospitalation from '../../assets/images/products/posthospitalization.jpeg'
import surgery from '../../assets/images/products/surgery.jpg'

const getIcon = ({ name, color, size }) => {
  let Icon
  switch (name) {
    case 'facebook':
      Icon = <IconFacebook color={color || '#000'} width={size} height={size} />
      break
    case 'linkedin':
      Icon = <IconLinkedin color={color || '#000'} width={size} height={size} />
      break
    case 'youtube':
      Icon = <IconYoutube color={color || '#000'} width={size} height={size} />
      break
    case 'instagram':
      Icon = (
        <IconInstagram color={color || '#000'} width={size} height={size} />
      )
      break

    default:
      break
  }

  return Icon
}

export default getIcon

export const getIconProduct = (name) => {
  let linkImg
  switch (name) {
    case 'examination':
      linkImg = examination
      break
    case 'surgery':
      linkImg = surgery
      break
    case 'non-surgical-treatments':
      linkImg = nonSurgery
      break
    case 'hospitalization':
      linkImg = hospitalization
      break
    case 'post-hospitalization-treatment':
      linkImg = postHospitalation
      break
    case 'outpatient-care':
      linkImg = outPatient
      break
    default:
      break
  }

  return linkImg
}

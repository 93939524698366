import _ from "lodash";
import React, { useState, useEffect } from "react";
import "./submenu-style.scss";
import SubMenuChildren from "./components/submenu-children";
import { useTranslation } from "react-i18next";
import { navigate } from "gatsby";

const ListSubMenu = ({
  handleSelectSubmenu,
  child,
  submenuSelected,
  titleMenuSelected,
  t,
  handleClick
}) => {
  
  const activeLink = submenuSelected.slug
    ? submenuSelected.slug === child.slug
    : submenuSelected.id === child.id;
  
  
    // console.log(submenuSelected)

  return (
    <div
      onMouseEnter={() => handleSelectSubmenu(child)}
      onClick={() => handleClick(child)}
      className={
        activeLink
          ? "submenu__list__button noLink hover"
          : "submenu__list__button noLink"
      }
    >
      
      <span>
        {titleMenuSelected === "our-products"
          ? child.title
          : t(`menu.${titleMenuSelected}.children.${child.title}.title`, {
              country: process.env.GATSBY_COUNTRY,
            })}
      </span>
      
    </div>
  );
};

const SubMenu = ({
  t,
  menuSelectedItem,
  handleMouseEnter,
  handleMouseLeave,
  menu,
}) => {
  const [menuSelected, setMenuSelected] = useState([]);
  const [submenuSelected, setSubmenuSelected] = useState([]);
  const [titleMenuSelected, setTitleMenuSelected] = useState(null);
  const { i18n } = useTranslation(); 

  useEffect(() => {
    setTitleMenuSelected(null);
    setSubmenuSelected([]);
    setMenuSelected([]);
    const selected = menu[menuSelectedItem];
    const firstchild =
      _.get(selected, "children") && _.get(selected, "children[0]", null);
    setTitleMenuSelected(_.get(selected, "title"));
    setSubmenuSelected(firstchild);
    setMenuSelected(selected);
  }, [menuSelectedItem]);
  
  
  const handleSelectSubmenu = (child) => {
    setSubmenuSelected(child);        
    // console.log("🚀 ~ handleSelectSubmenu ~ child:", child)
  };

  const handleClick = (child) => {
  let locale = i18n.language;
  locale = locale === "en" ? "" : `/${locale}`;
    if (child.title === 'Malacca Trust Insurance' || child.title === 'ETIQA' || child.title === 'FPG Insurance') {     
      const targetSlug = child.slug.startsWith('/') ? `${locale}${child.slug}` : `${locale}/${child.slug}`;    
      window.location.href = targetSlug
      // navigate(targetSlug)
    }
  }


  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`container-fluid submenu__container ${menuSelectedItem === 1 ? 'add-height' : ''}`}
    >       

      <div className="container-xxl">
        <div className="row">          
          <div className="col-3 submenu__list">
            {_.get(menuSelected, "children")
              ? _.map(_.compact(menuSelected.children), (child, idx) => {
                  // Tambahkan logika untuk membatasi child jika judul menuSelected adalah 'our-products'
                  if (menuSelected.title === "our-products" && idx >= 3)
                    return null;
                  return (
                    <ListSubMenu
                      child={child}
                      idx={idx}
                      handleSelectSubmenu={handleSelectSubmenu}
                      submenuSelected={submenuSelected}
                      titleMenuSelected={titleMenuSelected}
                      t={t}
                      handleClick={handleClick}
                    />
                  );
                })
              : null}
          </div>
          <SubMenuChildren
            titleMenuSelected={titleMenuSelected}
            submenuSelected={submenuSelected}
            menuSelectedItem={menuSelectedItem}
            t={t}
          />
        </div>
      </div>
    </div>
  );
};

export default SubMenu;

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-partners-js": () => import("./../../../src/pages/about-partners.js" /* webpackChunkName: "component---src-pages-about-partners-js" */),
  "component---src-pages-about-us-entity-js": () => import("./../../../src/pages/about-us-entity.js" /* webpackChunkName: "component---src-pages-about-us-entity-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-avail-js": () => import("./../../../src/pages/avail.js" /* webpackChunkName: "component---src-pages-avail-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-claim-js": () => import("./../../../src/pages/claim.js" /* webpackChunkName: "component---src-pages-claim-js" */),
  "component---src-pages-contact-form-js": () => import("./../../../src/pages/contact-form.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-corporate-governace-js": () => import("./../../../src/pages/corporate-governace.js" /* webpackChunkName: "component---src-pages-corporate-governace-js" */),
  "component---src-pages-customer-loyalty-program-js": () => import("./../../../src/pages/customer-loyalty-program.js" /* webpackChunkName: "component---src-pages-customer-loyalty-program-js" */),
  "component---src-pages-data-privacy-js": () => import("./../../../src/pages/data-privacy.js" /* webpackChunkName: "component---src-pages-data-privacy-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-emergency-assistance-js": () => import("./../../../src/pages/emergency-assistance.js" /* webpackChunkName: "component---src-pages-emergency-assistance-js" */),
  "component---src-pages-experience-js": () => import("./../../../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-find-hospitals-js": () => import("./../../../src/pages/find-hospitals.js" /* webpackChunkName: "component---src-pages-find-hospitals-js" */),
  "component---src-pages-how-to-avail-js": () => import("./../../../src/pages/how-to-avail.js" /* webpackChunkName: "component---src-pages-how-to-avail-js" */),
  "component---src-pages-how-to-claim-js": () => import("./../../../src/pages/how-to-claim.js" /* webpackChunkName: "component---src-pages-how-to-claim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurance-basics-js": () => import("./../../../src/pages/insurance-basics.js" /* webpackChunkName: "component---src-pages-insurance-basics-js" */),
  "component---src-pages-key-information-js": () => import("./../../../src/pages/key-information.js" /* webpackChunkName: "component---src-pages-key-information-js" */),
  "component---src-pages-key-terms-js": () => import("./../../../src/pages/key-terms.js" /* webpackChunkName: "component---src-pages-key-terms-js" */),
  "component---src-pages-news-events-js": () => import("./../../../src/pages/news-events.js" /* webpackChunkName: "component---src-pages-news-events-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-offices-js": () => import("./../../../src/pages/our-offices.js" /* webpackChunkName: "component---src-pages-our-offices-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-quotation-js": () => import("./../../../src/pages/quotation.js" /* webpackChunkName: "component---src-pages-quotation-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-teleheath-js": () => import("./../../../src/pages/teleheath.js" /* webpackChunkName: "component---src-pages-teleheath-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-under-construction-page-js": () => import("./../../../src/pages/under-construction-page.js" /* webpackChunkName: "component---src-pages-under-construction-page-js" */)
}


import _ from 'lodash'
import { Link } from 'gatsby'
import React from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import { getRoute } from '../../../helpers'
import ArrowRight from '../../../assets/icons/pacificcross_icons_arrow-right.svg'
import { withTrans } from '../../../i18n/withTrans'

const CardExperiences = ({ m, t, i18n }) => (
  <div className="container-fluid row submenu__group__card g-4">
    
    {_.map(m.children, (item, idx) => (
      <div className="col-4 submenu__group__col" key={idx}>
        <div className="submenu__group__card__card">
          <h5>{_.get(item, 'name')}</h5>
          <LinesEllipsis
            text={_.get(item, 'excerpt')}
            maxLine="2"
            ellipsis=" ..."
            trimRight
            basedOn="letters"
          />
          <Link to={getRoute({
            locale: i18n.language,
            route: `/experience/${_.get(item, 'slug')}`,
          })}>
            <span>
              {t('menu.link.learn')} <ArrowRight width="20" fill="#FF9124" />
            </span>
          </Link>
        </div>
      </div>
    ))}
  </div>
)

export default withTrans(CardExperiences)

import React from 'react'
import './footer-styles.scss'
import { Link } from 'gatsby'
import { getRoute } from '../../helpers'
import FooterLogoColumn from './components/footer-logo-column'
import { useMenu } from '../../hooks/use-menu'
import Accordion from '../accordion'

const FooterMobile = ({ t, i18n, socialmedia }) => {
  const { menuMobFooter, address } = useMenu()

  return (
    <div className="container-fluid footer-mobile__container">
      <div className="row px-3">
        <FooterLogoColumn t={t} socialmedia={socialmedia} address={address} />
        <Accordion data={menuMobFooter} t={t} i18n={i18n} />
        <div className="footer__bottom">
          <div className="copyright">
            <span>
              {' '}
              {t('footer.bottom-menu.rights', {
                year: new Date().getFullYear(),
              })}
            </span>
          </div>
          <div>
            <ul className="footer-mobile__list">
              {process.env.GATSBY_HAS_EMPLOYEES === 'true' ? (
                <li>
                  <Link to={getRoute({
                    locale: i18n.language,
                    route: '/employee/',
                  })}>
                    <span>{t('footer.bottom-menu.employee')}</span>
                    {' |'}
                  </Link>
                </li>
              ) : null}
              <li>
                <Link to={getRoute({
                  locale: i18n.language,
                  route: '/sitemap/',
                })}>
                  <span>{t('footer.bottom-menu.sitemap')}</span>
                  {' |'}
                </Link>
              </li>
              <li>
              <Link to={getRoute({
                locale: i18n.language,
                route: '/privacy-policy/',
              })}>
                <span>{t('footer.bottom-menu.privacy')}</span>
                {process.env.GATSBY_COUNTRY !== "Indonesia" && (
                  <span> {'|'} </span>
                )}
              </Link>
            </li>

              {/* {process.env.GATSBY_COUNTRY !== "Indonesia" ? (<li> */}
                <li>
                <Link to={getRoute({
                  locale: i18n.language,
                  route: '/terms-and-conditions/',
                })}>
                  <span>{t('footer.bottom-menu.terms-conditions')}</span>
                </Link>
              </li>
              {/* ) : null } */}
              
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterMobile

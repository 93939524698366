import React, { useState, useRef, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import _ from 'lodash'
import { withTrans } from '../../i18n/withTrans'
import Menu from '../menu'
import SubMenu from '../submenu'
import { useMenu } from '../../hooks/use-menu'
import { useUI } from '../../hooks/use-ui'
import { getRoute } from '../../helpers'
import LanguageIcon from '../../assets/icons/pacificcross_icons_language.svg'
import Logo from '../../assets/icons/pacificcross_logo_main.svg'
import './header-styles.scss'
import BurgerIcon from '../../assets/icons/pacificcross-icon-burgermenu.svg'

const Header = ({ t, i18n, location }) => {
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false)
  const [isOpenLanguageMenu, setIsOpenLanguageMenu] = useState(false)
  const [isOpenContactMenu, setIsOpenContactMenu] = useState(false)
  const [menuSelectedItem, setMenuSelectedItem] = useState(null)
  const [selectedMenuFirstLevel, setSelectedMenuFirstLevel] = useState(null)
  const { menu, topMenu } = useMenu()
  const { handleMenu } = useUI()

  const languageAvailableNumber = process.env.GATSBY_AVAILABLE_LOCALES.split(' ')

  const handleMouseLeave = () => {
    setIsOpenSubMenu(false)
  }
  const handleMouseEnter = () => {
    setIsOpenSubMenu(true)
  }

  const handleSelectMenu = (idx) => {    
    setMenuSelectedItem(idx)
  }

  const handleMenuContact = (willOpen) => {
    setIsOpenContactMenu(willOpen)
  }

  const onHandleLanguageMenu = (accion) => {
    setIsOpenLanguageMenu(accion)
  }


  const onChangeLanguage = (lng) => {
    const { languages, language } = i18n

    if (language !== lng) {
      const prevLng = _.head(_.filter(languages, (la) => la !== lng))
      const { pathname } = location
      const replace = `\\/${prevLng}\\/`
      const re = new RegExp(replace, 'gi')
      const path = lng === 'en' ? pathname.replace(re, '') : pathname
      const url = lng === 'en' ? `/${path}` : `/${lng}${path}`
      navigate(url)
    }
  }


  
// asli
// const onChangeLanguage = (lng) => {
//   const { languages, language } = i18n;
//   console.log("🚀 ~ i18n.changeLanguage ~ lng:", lng);        
//   if (language !== lng) {
//     const prevLng = _.head(_.filter(languages, (la) => la !== lng));
//     const { pathname } = location;
//     const replace = `\\/${prevLng}\\/`;
//     const re = new RegExp(replace, 'gi');
//     const path = lng === 'en' ? pathname.replace(re, '') : pathname;
//     let url = pathname.includes(`/${lng}/`) ? pathname.replace(`/${lng}/`, `/${lng}/`) : `/${lng}${path}`.replace(/\/{2,}/g, '/').replace(/\/{2,}$/, '/');

//     if (i18n.changeLanguage) {
//       i18n.languages = [lng, ...languages.filter((la) => la !== lng)];
//       i18n.changeLanguage(lng, () => {        
//         if (lng === 'en') {
//           // window.location.href = path; // Kembali ke default jika bahasa yang dipilih adalah 'en'
//         } else {
//           // window.location.href = url; // Update URL setelah perubahan bahasa selesai
//         }
//       });
//     }
//   }
// }

  const dropdownContainerRef1 = useRef(null);
  const dropdownContainerRef2 = useRef(null);


  // const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isDropdownVisible1, setDropdownVisible1] = useState(false);
  const [isDropdownVisible2, setDropdownVisible2] = useState(false);

  const toggleDropdown1 = () => {
    setDropdownVisible1(!isDropdownVisible1);
    setDropdownVisible2(false); // Hide the second dropdown
  };


  const toggleDropdown2 = () => {
    setDropdownVisible2(!isDropdownVisible2);
    setDropdownVisible1(false); // Hide the first dropdown
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownContainerRef1.current &&
        !dropdownContainerRef1.current.contains(event.target) &&
        isDropdownVisible1
      ) {
        setDropdownVisible1(false);
      }
  
      if (
        dropdownContainerRef2.current &&
        !dropdownContainerRef2.current.contains(event.target) &&
        isDropdownVisible2
      ) {
        setDropdownVisible2(false);
      }
    };
  
    // Attach the event listener
    document.addEventListener('click', handleClickOutside);
  
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownVisible1, isDropdownVisible2]);
  

  return (
    <div className="container-fluid header__container">
      <div className="container-xxl">
        <div className="row pt-4-sm pt-1 justify-content-between">
          <div className="col-5 col-sm-3 header__logo">
            <Link to={getRoute({
              locale: i18n.language,
              route: '/',
            })}>
              <Logo />
            </Link>
          </div>
          <div className="col-4 col-sm-9 flex-column justify-content-end header__menu mb-2">
            
            <div className="header__menu__top">
              <ul>
                {topMenu.length > 0
                ? _.map(topMenu, (item) => (
                  <li key={item.text}>
                                {item.text.toLowerCase() === 'pay medical premium' ? (
              <div className="dropdown-container" ref={dropdownContainerRef1}>
                <button onClick={toggleDropdown1}>
                 <div><span>{item.text}</span></div> 
                 </button>
                <div
                  className={`dropdown-content ${
                    isDropdownVisible1 ? 'active' : ''
                  }`}
                >
                  <a href='https://page.portone.cloud/PCIIMedicalPaymentPHP'><p>Pay In Peso </p></a>                  
                  <a href='https://page.portone.cloud/PCIIMedicalPaymentUSD'><p>Pay In Dollars </p></a> 
                </div>
              </div>
            ) : item.text.toLowerCase() === 'pay travel premium' ? (
              <div className="dropdown-container" ref={dropdownContainerRef2}>
                <button onClick={toggleDropdown2}><div><span>{item.text}</span></div></button>
                <div
                  className={`dropdown-content ${
                    isDropdownVisible2 ? 'active' : ''
                  }`}
                >
                  <a href='https://page.portone.cloud/PCIITravelPaymentPHP'><p>Pay In Peso </p></a>                  
                  <a href='https://page.portone.cloud/PCIITravelPaymentUSD'><p>Pay In Dollars </p></a> 
                </div>
              </div>
            ) : (
              <a href={item.link} target="_blank" rel="noreferrer">
                <div>
                  <span>{item.text}</span>
                </div>
              </a>
            )}
                  </li>
                ))
                : null}
                
                    <li
                      onMouseEnter={() => onHandleLanguageMenu(true)}
                      onMouseLeave={() => onHandleLanguageMenu(false)}
                      className="col-1 header__language">
                      <p
                        className="header__language__text"

                      >
                        <LanguageIcon width="16" fill="#3B3B3B"/>
                      </p>
                      { isOpenLanguageMenu && (
                        <div className="header__language__menu">
                          <div className="header__language__menu__box">
                            <ul>
                              {_.map(languageAvailableNumber, (child) => (
                                <li key={child} onClick={() => onChangeLanguage(child)}>
                                  <span>{child}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </li>                                 
              </ul>
            </div>

            
            <Menu
              handleMouseLeave={handleMouseLeave}
              handleMouseEnter={handleMouseEnter}
              selectMenu={handleSelectMenu}
              selectMenuContact={handleMenuContact}
              isOpenContactMenu={isOpenContactMenu}
              selectedMenuFirstLevel={selectedMenuFirstLevel}
              setSelectedMenuFirstLevel={setSelectedMenuFirstLevel}
              isOpenSubMenu={isOpenSubMenu}
              menu={menu}
              t={t}
              i18n={i18n}
            />
          </div>
          <div className="header__burger" onClick={() => handleMenu()}>
            <BurgerIcon width="100" />
          </div>
        </div>
      </div>
      {isOpenSubMenu && (
        <SubMenu
          t={t}
          menuSelectedItem={menuSelectedItem}
          handleMouseLeave={handleMouseLeave}
          handleMouseEnter={handleMouseEnter}
          menu={menu}
        />
      )}

    </div>
  )
  
}

export default withTrans(Header)

/* eslint-disable global-require */
import i18next from 'i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import getData from './callback'

let ready
let readyCallback

i18next
  // .use(LanguageDetector)
  .use(ChainedBackend)
  .init({
    defaultLanguage: process.env.GATSBY_LOCALE,
    fallbackLng: process.env.GATSBY_LOCALE,
    // resources: {
    //   ph: {
    //     translations: require('./locales/ph/ph.json'),
    //   },
    //   en: {
    //     translations: require('./locales/en/en.json'),
    //   },
    // },
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend((language, namespace, callback) => {
          getData(language)
            .then((resources) => {
              callback(null, resources)
            })
            .catch((error) => {
              callback(error, null)
            })
        }),
      ],
      backendOptions: [{
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      }],
    },
    ns: ['en'],
    defaultNS: process.env.GATSBY_LOCALE,
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      useSuspense: false,
      wait: true,
    },
  })
  .then(() => {
    ready = true
    if (readyCallback) {
      readyCallback()
    }
  })

i18next.languages = process.env.GATSBY_AVAILABLE_LOCALES.split(' ')

export const setCallback = (callback) => {
  readyCallback = callback
  if (ready) {
    readyCallback()
  }
}

export default i18next

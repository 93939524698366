import _ from 'lodash'
import React from 'react'
import FooterBlock from './footer-block'

const FooterColumn = ({ menu, productTitles }) => {
  // console.log("🚀 ~ menu:", menu)
  return _.map(menu, (item, idx) => (
    <FooterBlock productTitles={productTitles} data={item} key={idx} />
  ))
}

export default FooterColumn
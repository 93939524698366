// import _ from 'lodash'
// import React, {
//   createContext, useState, useContext, useEffect,
// } from 'react'
// import { useTranslation } from 'react-i18next'
// import { makeRequest } from '../helpers'
// import { menuData, menuMobileFooter } from '../data'
// import i18next from 'i18next'

// const MenuContext = createContext('')

// const MenuProvaider = ({ children }) => {
//   const { t } = useTranslation()
//   const [menu, setMenu] = useState([])
//   const [topMenu, setTopMenu] = useState([])
//   const [portals, setPortals] = useState({})
//   const [address, setAddress] = useState({})
//   const [menuMobFooter, setMenuMobFooter] = useState([])
//   const [loading, setLoading] = useState(false)
//   const [locale, setLocale] = useState(i18next.language);

//   const getLinksTopBuy = async () => {
//     const headers = {
//       'Content-Type': 'application/json',
//     }
//     const result = makeRequest({
//       headers,
//       endPoint: 'links',
//       params: {
//         loc: 'top-menu',
//       },
//     })
//       .then((resp) => {
//         const res = _.slice(resp.data, 0, 5)
//         setTopMenu(res)
//       })
//       .catch((error) => {
//         console.error(error)
//       })

//     return result
//   }

//   const getLinksAddress = async () => {
//     const headers = {
//       'Content-Type': 'application/json',
//     }
//     const result = makeRequest({
//       headers,
//       endPoint: 'links',
//       params: {
//         loc: 'footer-address',
//       },
//     })
//       .then((resp) => {
//         const res = resp.data
//         setAddress(res)
//       })
//       .catch((error) => {
//         console.error(error)
//       })

//     return result
//   }

//   const getMenuPortals = async () => {
//     const headers = {
//       'Content-Type': 'application/json',
//     }
//     const result = makeRequest({
//       headers,
//       endPoint: 'links',
//       params: {
//         loc: 'portals',
//       },
//     })

//     return result
//   }

//   const getMenu = async (locale) => {
//     const headers = {
//       'Content-Type': 'application/json',
//     }
//     const result = makeRequest({
//       headers,
//       endPoint: 'productCategoryMenu',
//       params: {
//         locale
//       //   locale: i18n.language,
//       },
//     })

//     return result
//   }

//   const getMenuMatters = async (locale) => {
//     const headers = {
//       'Content-Type': 'application/json',
//     }
//     const result = makeRequest({
//       headers,
//       endPoint: 'youMatterMenu',
//       params: {
//         locale
//       //   locale: i18n.language,
//       },
//     })

//     return result
//   }

//   useEffect(() => {
//     setLocale(i18next.language);
//   }, [i18next.language]);

//   useEffect(() => {
//     makeMenu()
//     getLinksTopBuy()
//     getLinksAddress()
//   }, [])

//   const makeMenu = async () => {
//     let newMenu = []
//     let menuReform = _.compact(menuData)
//     setMenu([])
//     setLoading(true)
//     const [prodcutsResult, matterResults, portalsResults] = await Promise.all([
//       getMenu(locale),
//       getMenuMatters(locale),
//       getMenuPortals(),
//     ])
//     setPortals({
//       title: t('portals.title'),
//       children: portalsResults.data.map((child) => ({
//         title: child.text,
//         url: child.link,
//       })),
//     })
//     newMenu = _.map(menuReform, (data) => {
//       let newData

//       switch (data.title) {
//         case 'our-products':
//           data.children.splice(0, prodcutsResult.data.length)
//           data.children.push(...prodcutsResult.data)
//           _.uniqBy(data.children, 'id')
//           newData = data
//           break
//         case 'matter': {
//           const result = _.map(data.children, (child) => {
//             if (child.title === 'experience') {
//               return {
//                 ...child,
//                 children: [...matterResults.data],
//               }
//             }
//             return child
//           })
//           data.children.splice(0, 2)
//           data.children.push(...result)
//           newData = data
//           break
//         }
//         case 'claims': {
//           const resultPortals = _.compact(_.map(data.children, (child) => {
//             if (child.title === 'portal-apps') {
//               return {
//                 ...child,
//                 subgroup: _.map(child.subgroup, (subgroup) => {
//                   if (subgroup.title === 'portals') {
//                     return {
//                       ...subgroup,
//                       children: portalsResults.data.map((ch) => ({
//                         title: ch.text,
//                         url: ch.link,
//                       })),
//                     }
//                   }
//                   return subgroup
//                 }),
//               }
//             }
//             return child
//           }))
//           data.children.splice(0, 4)
//           data.children.push(...resultPortals)
//           newData = data
//           break
//         }

//         default:
//           newData = data
//           break
//       }
//       return newData
//     })
//     setMenuMobFooter([...newMenu[0].children, ...menuMobileFooter])
//     setMenu(newMenu)
//     setLoading(false)
//     menuReform = []
//   }



//   const values = {
//     loading,
//     getMenu,
//     menuMobFooter,
//     menu,
//     topMenu,
//     portals,
//     address,
//   }

//   return <MenuContext.Provider value={values}>{children}</MenuContext.Provider>
// }

// const useMenu = () => {
//   const context = useContext(MenuContext)

//   if (!context) {
//     throw new Error('useLocale must be used within an MenuProvaider')
//   }

//   return context
// }

// export { MenuProvaider, useMenu }


import _ from 'lodash'
import React, { createContext, useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../helpers'
import { menuData, menuMobileFooter } from '../data'
import i18next from 'i18next'

const MenuContext = createContext('')

const MenuProvaider = ({ children }) => {
  const { t } = useTranslation()
  const [menu, setMenu] = useState([])
  const [topMenu, setTopMenu] = useState([])
  const [portals, setPortals] = useState({})
  const [address, setAddress] = useState({})
  const [menuMobFooter, setMenuMobFooter] = useState([])
  const [loading, setLoading] = useState(false)
  const [locale, setLocale] = useState(i18next.language)

  const getLinksTopBuy = async () => {
    try {
      const headers = { 'Content-Type': 'application/json' }
      const resp = await makeRequest({
        headers,
        endPoint: 'links',
        params: { loc: 'top-menu' }
      })
      const res = _.slice(resp.data, 0, 5)
      setTopMenu(res)
    } catch (error) {
      console.error(error)
    }
  }

  const getLinksAddress = async () => {
    try {
      const headers = { 'Content-Type': 'application/json' }
      const resp = await makeRequest({
        headers,
        endPoint: 'links',
        params: { loc: 'footer-address' }
      })
      setAddress(resp.data)
    } catch (error) {
      console.error(error)
    }
  }

  const getMenuPortals = async () => {
    try {
      const headers = { 'Content-Type': 'application/json' }
      const resp = await makeRequest({
        headers,
        endPoint: 'links',
        params: { loc: 'portals' }
      })
      return resp
    } catch (error) {
      console.error(error)
    }
  }

  const getMenu = async (locale) => {
    try {
      const headers = { 'Content-Type': 'application/json' }
      const resp = await makeRequest({
        headers,
        endPoint: 'productCategoryMenu',
        params: { locale }
      })
      return resp
    } catch (error) {
      console.error(error)
    }
  }

  const getMenuMatters = async (locale) => {
    try {
      const headers = { 'Content-Type': 'application/json' }
      const resp = await makeRequest({
        headers,
        endPoint: 'youMatterMenu',
        params: { locale }
      })
      return resp
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const updateLocale = () => {
      setLocale(i18next.language)
    }
    i18next.on('languageChanged', updateLocale)
    return () => {
      i18next.off('languageChanged', updateLocale)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      await makeMenu()
      await getLinksTopBuy()
      await getLinksAddress()
    }
    fetchData()
  }, [locale])

  const makeMenu = async () => {
    setLoading(true)
    setMenu([])
    let menuReform = _.compact(menuData)
    const [prodcutsResult, matterResults, portalsResults] = await Promise.all([
      getMenu(locale),
      getMenuMatters(locale),
      getMenuPortals()
    ])
    setPortals({
      title: t('portals.title'),
      children: portalsResults.data.map(child => ({
        title: child.text,
        url: child.link,
      })),
    })
    const newMenu = _.map(menuReform, data => {
      switch (data.title) {
        case 'our-products':
          data.children = [...prodcutsResult.data, ..._.uniqBy(data.children, 'id')]
          break
        case 'matter':
          data.children = data.children.map(child => {
            if (child.title === 'experience') {
              return { ...child, children: [...matterResults.data] }
            }
            return child
          })
          break
        case 'claims':
          data.children = data.children.map(child => {
            if (child.title === 'portal-apps') {
              return {
                ...child,
                subgroup: child.subgroup.map(subgroup => {
                  if (subgroup.title === 'portals') {
                    return {
                      ...subgroup,
                      children: portalsResults.data.map(ch => ({
                        title: ch.text,
                        url: ch.link,
                      })),
                    }
                  }
                  return subgroup
                })
              }
            }
            return child
          })
          break
        default:
          break
      }
      return data
    })
    setMenuMobFooter([...newMenu[0].children, ...menuMobileFooter])
    setMenu(newMenu)
    setLoading(false)
    menuReform = []
  }

  const values = {
    loading,
    getMenu,
    menuMobFooter,
    menu,
    topMenu,
    portals,
    address,
  }

  return <MenuContext.Provider value={values}>{children}</MenuContext.Provider>
}

const useMenu = () => {
  const context = useContext(MenuContext)
  if (!context) {
    throw new Error('useMenu must be used within a MenuProvaider')
  }
  return context
}

export { MenuProvaider, useMenu }

import image1 from '../assets/images/tmp/gettyimages-1031430214-2048x2048.jpeg'
import image2 from '../assets/images/tmp/gettyimages-1014228976-2048x2048.jpeg'
import image3 from '../assets/images/tmp/gettyimages-102284192-2048x2048.jpeg'
import image4 from '../assets/images/tmp/gettyimages-141655160-2048x2048.jpeg'
import image5 from '../assets/images/tmp/gettyimages-521981107-2048x2048.jpeg'
import image6 from '../assets/images/tmp/gettyimages-800945042-2048x2048.jpeg'

export const menuData = [
  {
    title: 'our-products',
    slug: 'our-products',
    id: 'our-products',
    children: [],
  },
  process.env.GATSBY_HAS_YOU_MATTER === 'true' && {
    title: 'matter',
    slug: 'matter',
    id: 'matter',
    children: [
      {
        title: 'experience',
        slug: null,
        id: 'experience',
        children: [{}],
      },
      {
        title: 'knowledge',
        slug: null,
        id: 'knowledge',
        subgroup: [
          {
            title: 'learning-resources',
            children: [
              {
                title: 'key',
                slug: 'key-terms',
              },
              {
                title: 'insurance',
                slug: 'insurance-basics',
              }
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'claims',
    slug: 'claims-support',
    id: 'claims-support',
    children: [
      process.env.GATSBY_HAS_CLAIM === 'true' && {
        title: 'how-claim',
        id: 'how-to-claim',
        subgroup: [
          {
            title: 'categories',
            children: [
              {
                title: 'medical-plans',
                slug: 'how-to-claim',
              },
              {
                title: 'travel-plans',
                slug: 'how-to-claim',
              },
              {
                title: 'personal-accident',
                slug: 'how-to-claim',
              },
              {
                title: 'tpa-other-services',
                slug: 'how-to-claim',
              },
              {
                title: 'other-products',
                slug: 'how-to-claim',
              },
            ],
          },
        ],
      },
      process.env.GATSBY_HAS_AVAIL === 'true' && {
        title: 'how-to-avail',
        id: 'how-to-avail',
        subgroup: [
          {
            title: 'categories',
            children: [
              {
                title: 'medical-plans',
                slug: 'how-to-avail',
              },
              {
                title: 'travel-plans',
                slug: 'how-to-avail',
              },
              {
                title: 'personal-accident',
                slug: 'how-to-avail',
              },
              {
                title: 'tpa-other-services',
                slug: 'how-to-avail',
              },
              {
                title: 'other-products',
                slug: 'how-to-avail',
              },
            ],
          },
        ],
      },
      {
        title: 'support-services',
        id: 'support-services',
        children: [
          {
            title: 'download-centre',
            slug: 'download',
          },
          {
            title: 'find-hospitals',
            slug: 'find-hospitals',
          },
          {
            title: 'faq',
            slug: 'faq',
          },
          {
            title: 'key-information',
            slug: 'key-information',
          },
          {
            title: 'emergency-assistance',
            slug: 'emergency-assistance',
          },
          {
            title: 'teleheath',
            slug: 'teleheath',
          },
        ],
      },
      {
        title: 'portal-apps',
        id: 'portal-apps',
        subgroup: [
          {
            title: 'portals',
            children: [
              {
                title: 'sassi',
                slug: 'sassi',
                url: process.env.GATSBY_SASSI_URL,
              },
              process.env.GATSBY_HAS_CLIENT_PORTAL === 'true' && {
                title: 'client-portal',
                slug: 'client-portal',
                url: process.env.GATSBY_CLIENT_PORTAL_URL,
              },
              {
                title: 'medical-provider',
                slug: 'medical-provider',
                url: 'https://webapp.pacificcross.com.ph/OnlineProviderAccreditation',
              },
              process.env.GATSBY_HAS_PERSONAL_HEALTH_RECORD === 'true' && {
                title: 'personal-health-record',
                slug: 'personal-health-record',
                url: process.env.GATSBY_PERSONAL_HEALTH_RECORD_URL,
              },
            ],
          },
          {
            title: 'apps',
            children: [
              {
                title: 'heath-insurace-app-title',
                url: {
                  ios: process.env.GATSBY_APP_IOS_URL,
                  android: process.env.GATSBY_APP_ANDROID_URL,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'about-us',
    slug: 'about-us',
    id: 'about-us',
    children: [
      {
        title: 'pacific-cross-country',
        slug: 'pacific-cross-country',
        id: 'pacific-cross-country',
        subgroup: [
          {
            title: 'about-pacific-cross-country',
            children: [
              {
                title: 'about-us',
                slug: 'about-us-entity',
              },
              {
                title: 'customer-loyalty-program',
                slug: 'customer-loyalty-program',
              },
              {
                title: 'blogs',
                slug: 'blogs',
              },
              {
                title: 'news-events',
                slug: 'news-events',
              },
              {
                title: 'careers',
                slug: 'careers',
              },                            
            ],
          },
        ],
      },
      {
        title: 'pacific-cross',
        slug: 'pacific-cross',
        id: 'pacific-cross',
        subgroup: [
          {
            title: 'about-pacific-cross',
            children: [
              {
                title: 'about-us',
                slug: 'about-us',
              },
            ],
          },
        ],
      },
    ],
  },
]

export const contactMenuSitemap = {
  title: 'contact',
  slug: 'contact',
  id: 'contact',
  children: [
    {
      title: 'contact-with-us',
      slug: 'contact-with-us',
      children: [
        {
          title: 'contact-form',
          slug: 'contact-form',
        },
        process.env.GATSBY_HAS_QUOTE === 'true' && {
          title: 'get-quote',
          slug: 'quotation',
        },
        {
          title: 'our-offices',
          slug: 'our-offices',
        },
      ],
    },
  ],
}

export const paymentMenuSitemap = {
  title: 'payment',
  slug: 'payment',
  id: 'payment',
  children: [
    {
      title: 'payments',
      slug: 'payments',
      children: [
        {
          title: 'medical-plan',
          slug: 'medical-plan',
        },
        {
          title: 'travel-plan',
          slug: 'travel-plan',
        },
      ],
    },
  ],
}

export const contactMenu = {
  title: 'contact',
  slug: 'contact',
  id: 'contact',
  children: [
    {
      title: 'contact-form',
      slug: 'contact-form',
    },
    process.env.GATSBY_HAS_QUOTE === 'true' && {
      title: 'get-quote',
      slug: 'quotation',
    },
    {
      title: 'our-offices',
      slug: 'our-offices',
    },
  ],
}

export const menuFooter = [
  [],
  [
    {
      title: 'how-claim',
      slug: 'how-to-claim',
      subgroup: [
        {
          title: 'categories',
          children: [
            {
              title: 'medical-plans',
              slug: 'how-to-claim',
            },
            {
              title: 'travel-plans',
              slug: 'how-to-claim',
            },
            {
              title: 'personal-accident',
              slug: 'how-to-claim',
            },
            {
              title: 'tpa-other-services',
              slug: 'how-to-claim',
            },
            {
              title: 'other-products',
              slug: 'how-to-claim',
            },
          ],
        },
      ],
    },
  ],
  [
    {
      title: 'support-services',
      slug: 'support-services',
      children: [
        {
          title: 'download-centre',
          slug: 'download',
        },
        {
          title: 'find-hospitals',
          slug: 'find-hospitals',
        },
        {
          title: 'faq',
          slug: 'faq',
        },
        {
          title: 'key-information',
          slug: 'key-information',
        },
        {
          title: 'emergency-assistance',
          slug: 'emergency-assistance',
        },
        {
          title: 'teleheath',
          slug: 'teleheath',
        },
      ],
    },
    {
      title: 'portals',
      children: [
        {
          title: 'sassi',
          slug: 'sassi',
          url: process.env.GATSBY_SASSI_URL,
        },
        process.env.GATSBY_HAS_CLIENT_PORTAL === 'true' && {
          title: 'client-portal',
          slug: 'client-portal',
          url: process.env.GATSBY_CLIENT_PORTAL_URL,
        },
        {
          title: 'medical-provider',
          slug: 'medical-provider',
          url: 'https://webapp.pacificcross.com.ph/OnlineProviderAccreditation',
        },
        process.env.GATSBY_HAS_PERSONAL_HEALTH_RECORD === 'true' && {
          title: 'personal-health-record',
          slug: 'personal-health-record',
          url: process.env.GATSBY_PERSONAL_HEALTH_RECORD_URL,
        },
      ],
    },
    {
      title: 'about-us',
      slug: 'about-us',
      subgroup: [
        {
          title: process.env.GATSBY_COUNTRY,
          children: [
            {
              title: 'about-us',
              slug: 'about-us-entity',
            },
            {
              title: 'customer-loyalty-program',
              slug: 'customer-loyalty-program',
            },
            {
              title: 'blogs',
              slug: 'blogs',
            },
            {
              title: 'news-events',
              slug: 'news-events',
            },
            {
              title: 'careers',
              slug: 'careers',
            },            
            !process.env.GATSBY_HAS_DATA_PRIVACY === 'true' ? {
              title: 'data-privacy',
              slug: 'data-privacy',
            } : null,
          ],
        },
        {
          title: 'pacific-cross',
          children: [
            {
              title: 'about-us',
              slug: 'about-us',
            },
          ],
        },
      ],
    },
  ],
]

export const menuMobileFooter = [
  {
    title: 'how-claim',
    slug: 'how-to-claim',
    static: true,
    subgroup: [
      {
        title: 'categories',
        children: [
          {
            title: 'medical-plans',
            slug: 'how-to-claim',
          },
          {
            title: 'travel-plans',
            slug: 'how-to-claim',
          },
          {
            title: 'personal-accident',
            slug: 'how-to-claim',
          },
          {
            title: 'tpa-other-services',
            slug: 'how-to-claim',
          },
          {
            title: 'other-products',
            slug: 'how-to-claim',
          },
        ],
      },
    ],
  },
  {
    title: 'support-services',
    slug: 'support-services',
    static: true,
    children: [
      {
        title: 'download-centre',
        slug: 'download',
      },
      {
        title: 'find-hospitals',
        slug: 'find-hospitals',
      },
      {
        title: 'faq',
        slug: 'faq',
      },
      {
        title: 'key-information',
        slug: 'key-information',
      },
      {
        title: 'emergency-assistance',
        slug: 'emergency-assistance',
      },
      {
        title: 'teleheath',
        slug: 'teleheath',
      },
    ],
  },
  {
    title: 'portals',
    static: true,
    children: [
      {
        title: 'sassi',
        url: 'https://sassi.pacificcross.com.ph/#/login',
      },
      // no in phiipines,
      // {
      //   title: 'client-portal',
      //   url: 'https://www.google.com/',
      // },
      {
        title: 'medical-provider',
        url: 'https://webapp.pacificcross.com.ph/OnlineProviderAccreditation',
      },
      // {
      //   title: 'personal-health-record',
      //   url: 'https://www.google.com/',
      // },
    ],
  },
  {
    title: 'about-us',
    slug: 'about-us',
    static: true,
    subgroup: [
      {
        title: `About Pacific Cross ${process.env.GATSBY_COUNTRY}`,
        children: [
          {
            title: 'about-us',
            slug: 'about-us',
          },
          {
            title: 'customer-loyalty-program',
            slug: 'customer-loyalty-program',
          },
          {
            title: 'blogs',
            slug: 'blogs',
          },
          {
            title: 'news-events',
            slug: 'news-events',
          },
          {
            title: 'careers',
            slug: 'careers',
          },
          {
            title: 'data-privacy',
            slug: 'data-privacy',
          },
        ],
      },
    ],
  },
]

export const homeMatterData = {
  tag: 'our products',
  title: 'Your next move towards a better future.',
  list: [
    {
      title: 'Leading a healthier life',
      urlImage: image1,
    },
    {
      title: 'Preparing for medical expenses',
      urlImage: image2,
    },
    {
      title: 'Living life with peace of mind',
      urlImage: image3,
    },
    {
      title: 'Lessen the impact of serious illnesses',
      urlImage: image4,
    },
    {
      title: 'Protect what is most precious',
      urlImage: image5,
    },
    {
      title: 'Worry-free travels',
      urlImage: image6,
    },
  ],
}

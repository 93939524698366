/* eslint-disable no-confusing-arrow */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import DropdownArrow from '../../assets/icons/dropdwon-arrow.svg'
import './accordion-style.scss'

const sections = (accordionItems, click, t) => _.map(accordionItems, (i, idx) => {
  const arrowClass = classNames({
    accordion__arrow: true,
    accordion__arrow__active: i.isOpenByDefault ? true : i.open,
  })

  const blockContentClass = classNames({
    accordion__content: true,
    accordion__content__active: i.isOpenByDefault ? true : i.open,
  })

  const textContentClass = classNames({
    accordion__content__text: true,
    accordion__content__text__active: i.isOpenByDefault ? true : i.open,
  })

  const subTitleContentClass = classNames({
    accordion__content__subtitle: true,
    accordion__content__subtitle__active: i.isOpenByDefault ? true : i.open,
  })

  const Childrens = ({
    data, section, subgroup, index, isStatic,
  }) => _.map(data, (child, ind) => {
    const text = subgroup
      ? t(`footer.${section}.subgroup.${[index]}.children.${child.title}`)
      : t(`footer.${section}.children.${child.title}`)
    return child.url || child.slug ? (
      <p className={subTitleContentClass} key={ind}>
        {child.url ? (
          <a href={child.url} target="_blank" rel="noreferrer">
            <span className={textContentClass}>
              {isStatic ? text : child.title}
            </span>
          </a>
        ) : (
          <Link to={`/${child.slug}`}>
            <span className={textContentClass}>
              {isStatic ? text : child.title}
            </span>
          </Link>
        )}
      </p>
    ) : (
      <p className={subTitleContentClass} key={ind}>
        {isStatic ? text : child.title}
      </p>
    )
  })

  if (accordionItems.length === 0) return null
  return (
    <div className="accordion__container" key={i.title + idx}>
      <div className="accordion__section">
        {i.scr ? (
          <Link to={i.src}>
            <p className="accordion__section__title">
              {i.isStatic ? t(`footer.${i.title}.title`) : i.title}
            </p>
          </Link>
        ) : (
          <p className="accordion__section__title">
            {i.isStatic ? t(`footer.${i.title}.title`) : i.title}
          </p>
        )}
        {!i.isOpenByDefault && (
          <div
            className={arrowClass}
            onClick={() => click(accordionItems.indexOf(i))}
            isOpen={i.open}
          >
            <DropdownArrow width={30} />
          </div>
        )}
      </div>
      <div className={blockContentClass}>
        {i.subgroup
            && _.map(i.subgroup, (child, index) => (
              <React.Fragment key={index}>
                <p
                  className={subTitleContentClass}
                  style={{ fontWeight: 'bold' }}
                >
                  {t(child.title)}
                </p>
                <div className={textContentClass}>
                  <Childrens
                    isStatic={i.isStatic}
                    data={child.children}
                    section={i.title}
                    subgroup
                    index={index}
                  />
                </div>
              </React.Fragment>
            ))}
        {i.children && (
          <Childrens
            data={i.children}
            section={i.title}
            isStatic={i.isStatic}
          />
        )}
      </div>
    </div>
  )
})

const Accordion = ({ data = [], t, i18n }) => {
  const url = typeof window !== 'undefined' ? window.location.href : ''
  const [accordionItems, setAccordionItems] = useState([])
  useEffect(() => {
    const accordion = []
    data.forEach((i) => {
      accordion.push({
        title: i.title,
        input: !_.isObject(i) ? i.title : '',
        src: i.src,
        isStatic: i.static || false,
        children: i.children,
        subgroup: i.subgroup,
        isOpenByDefault: i.isOpenByDefault,
        open: false,
      })
    })
    setAccordionItems(accordion)
  }, [data, url, i18n.language])

  function click(i) {
    // const newAccordion = accordionItems.slice()
    if (accordionItems[i].open) {
      const newAccordion = accordionItems.map((accordionItem) => ({
        ...accordionItem,
        open: false,
      }))
      setAccordionItems(newAccordion)
    } else {
      const newAccordion = accordionItems.map((accordionItem) => ({
        ...accordionItem,
        open: !!accordionItem[i],
      }))
      newAccordion[i].open = !newAccordion[i].open
      setAccordionItems(newAccordion)
    }
  }

  return <div className="accordion">{sections(accordionItems, click, t)}</div>
}

export default Accordion

import React from 'react'
import _ from 'lodash'
import '../footer-styles.scss'
import Logo from '../../../assets/icons/pacificcross_logo_main.svg'
import Button from '../../button'
import SocialMedia from '../../social-media'
import Applestore from '../../../assets/icons/pacificcross_icons_app_apple.svg'
import GooglePlay from '../../../assets/icons/pacificcross_icons_app_googleplay.svg'

const FooterLogoColumn = ({ t, socialmedia, address }) => (
  <div className="col">
    <div className="footer__logo">
      <Logo />
    </div>
    <div className="footer__address">
      <h5>{t('footer.contact.title')}</h5>
      <h6>{t('footer.contact.headquarters')}</h6>
      <div>
        <div dangerouslySetInnerHTML={{ __html: _.get(address, 'footer_address') }} />
      </div>
    </div>
    <div className="footer__inquire">
      <Button
        link={'/contact-form'}
        outline={true}
        text={t('footer.contact.button', 'Inquire Now')}
      />
    </div>
    <div className="footer__socialMedia">
      <SocialMedia data={socialmedia} />
    </div>
    <div className="footer__apps">
      {/* <h5>{t('footer.apps-title.title')}</h5>
      <div className="footer__apps__list">
        <a href={process.env.GATSBY_APP_IOS_URL} target="_blank" rel="noreferrer">
          <Applestore />
        </a>
        <a href={process.env.GATSBY_APP_ANDROID_URL} target="_blank" rel="noreferrer">
          <GooglePlay />
        </a>
      </div> */}
    </div>
  </div>
)

// eslint-disable-next-line no-multiple-empty-lines

export default FooterLogoColumn

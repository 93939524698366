// import _ from 'lodash'
// import { Link } from 'gatsby'
// import React, { useState, useEffect } from 'react'
// import { useTranslation } from 'react-i18next'
// import { getRoute } from '../../../helpers'
// import '../footer-styles.scss'

// const FooterBlock = ({
//   data, productTitles,
// }) => {  
//   const { i18n, t } = useTranslation()
//   const [group, setGroup] = useState(null)
//   const titleGroup = _.get(data, 'title')
//   const isFromBackend = _.includes(productTitles, _.get(data, 'title'))
//     || _.get(data, 'title') === t('portals.title')
//   const getGroups = () => {
//     if (Object.prototype.hasOwnProperty.call(data, 'subgroup')) {
//       const subgroup = _.compact(_.get(data, 'subgroup'))
//       const item = (
//         <div className="footer__block__subgroup">
//           {_.map(subgroup, (it, idx) => (
//             <div key={idx}>
//               <h6>
//                 {isFromBackend
//                   ? it.title
//                   : t(`footer.${titleGroup}.subgroup.${[idx]}.title`, {
//                     country: process.env.GATSBY_COUNTRY,
//                   })}
//               </h6>
//               <div>
//                 {_.map(_.compact(it.children), (child, i) => (child.url ? (
//                   <a
//                     href={child.url}
//                     target="_blank"
//                     rel="noreferrer"
//                     key={i}
//                   >
//                     <p>
//                       {isFromBackend
//                         ? child.title
//                         : t(
//                           `footer.${titleGroup}.subgroup.${[
//                             idx,
//                           ]}.children.${child.title}`,
//                         )}
//                     </p>
//                   </a>
//                 ) : (
//                   <Link
//                     key={i}
//                     to={getRoute({
//                       locale: i18n.language,
//                       route: `/${child.slug}`,
//                     })}>
//                     <p>
//                       {isFromBackend
//                         ? child.title
//                         : t(
//                           `footer.${titleGroup}.subgroup.${[
//                             idx,
//                           ]}.children.${child.title}`,
//                         )}
//                     </p>
//                   </Link>
//                 )))}
//               </div>
//             </div>
//           ))}
//         </div>
//       )
//       setGroup(item)
//     } else {
//       const children = _.compact(_.get(data, 'children'))
//       const item = (
//         <div className="footer__block__children">
//           {_.map(children, (child, i) => (child.url ? (
//             <a href={child.url} target="_blank" rel="noreferrer" key={i}>
//               <p key={i}>
//                 {isFromBackend
//                   ? child.title
//                   : t(`footer.${titleGroup}.children.${child.title}`)}
//               </p>
//             </a>
//           ) : (
//             <Link to={getRoute({
//               locale: i18n.language,
//               route: `/${child.slug}`,
//             })}
//             key={i} >
//               <p key={i}>
//                 {isFromBackend
//                   ? child.title
//                   : t(`footer.${titleGroup}.children.${child.title}`)}
//               </p>
//             </Link>
//           )))}
//         </div>
//       )
//       setGroup(item)
//     }
//   }

//   useEffect(() => {
//     getGroups()
//   }, [i18n.language])

//   return (
//     <div className="footer__block">
//       <h5>{isFromBackend ? titleGroup : t(`footer.${titleGroup}.title`)}</h5>
//       {group}
//     </div>
//   )
// }

// export default FooterBlock

import _ from 'lodash'
import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getRoute } from '../../../helpers'
import '../footer-styles.scss'

const FooterBlock = ({
  data, productTitles,
}) => {  
  const { i18n, t } = useTranslation()
  const [group, setGroup] = useState(null)
  const titleGroup = _.get(data, 'title')
  const isFromBackend = _.includes(productTitles, _.get(data, 'title'))
    || _.get(data, 'title') === t('portals.title')
  
  const getGroups = () => {
    if (Object.prototype.hasOwnProperty.call(data, 'subgroup')) {
      const subgroup = _.compact(_.get(data, 'subgroup'))
      const item = (
        <div className="footer__block__subgroup">          
          {_.map(subgroup, (it, idx) => (
            <div key={idx}>
              <h6>
                {isFromBackend
                  ? it.title
                  : t(`footer.${titleGroup}.subgroup.${[idx]}.title`, {
                    country: process.env.GATSBY_COUNTRY,
                  })}
              </h6>
              <div>
                {_.map(_.compact(it.children), (child, i) => (child.url ? (
                  <a
                    href={child.url}
                    target="_blank"
                    rel="noreferrer"
                    key={i}
                  >
                    <p>
                      {isFromBackend
                        ? child.title
                        : t(
                          `footer.${titleGroup}.subgroup.${[
                            idx,
                          ]}.children.${child.title}`,
                        )}
                    </p>
                  </a>
                ) : (
                  <Link
                    key={i}
                    to={getRoute({
                      locale: i18n.language,
                      route: `/${child.slug}`,
                    })}>
                    <p>
                      {isFromBackend
                        ? child.title
                        : t(
                          `footer.${titleGroup}.subgroup.${[
                            idx,
                          ]}.children.${child.title}`,
                        )}
                    </p>
                  </Link>
                )))}
              </div>
            </div>
          ))}
        </div>
      )
      setGroup(item)
    } else {
      const children = _.compact(_.get(data, 'children'))      
      const item = (
        <div className="footer__block__children">
          {_.map(children, (child, i) => (child.url ? (
            <a href={child.url} target="_blank" rel="noreferrer" key={i}>
              <p key={i}>
                {isFromBackend
                  ? child.title
                  : t(`footer.${titleGroup}.children.${child.title}`)}
              </p>
            </a>
          ) : (
            <Link to={getRoute({
              locale: i18n.language,
              route: `/${child.slug}`,
            })}
            key={i} >
              <p key={i}>
                {isFromBackend
                  ? child.title
                  : t(`footer.${titleGroup}.children.${child.title}`)}
              </p>
            </Link>
          )))}
        </div>
      )
      setGroup(item)
    }
  }

  useEffect(() => {
    getGroups()
  }, [i18n.language, data]) // Menambahkan data sebagai dependensi untuk memperbarui subgroup saat data berubah

  return (
    <div className="footer__block">
      <h5>{isFromBackend ? titleGroup : t(`footer.${titleGroup}.title`)}</h5>
      {group}
    </div>
  )
}

export default FooterBlock